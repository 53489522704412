/* Converter.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.converter {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  animation: fadeInUp 0.5s ease;
  font-family: 'Poppins', sans-serif; /* Usando a fonte Poppins */
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.floating-coins {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.coin {
  position: absolute;
  width: 30px;
  height: 30px;
  /* background-image: url('./img/coin.png'); */
  background-size: cover;
  animation: floatCoin linear infinite;
}

@keyframes floatCoin {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}

.input-field {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.rounded-input,
.select-currency,
.convert-button,
.cash-code {
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #2ecc71;
  width: 200px;
}

.select-currency {
  margin-left: 10px;
  width: 100px;
}

.icon {
  margin: 10px 0;
}

.convert-button{
  background-color: #2ecc71;
}

.cash-code {
  background-color: #2e75cc;
}

.convert-button,
.cash-code {
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px; /* Adicione margem superior para espaçamento */
}

.convert-button:hover {
  background-color: #27ae60;
}

.cash-code:hover {
  background-color: #124d94;
}

@media (max-width: 600px) {
  .input-field {
    flex-direction: column;
  }
}
